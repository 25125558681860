import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme?.secondary_color || 'white'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  z-index: 1000;

  .navbar-light .navbar-nav .nav-link {
    color: ${props => props.theme?.primary_color || '#000'};
    &:hover {
      color: ${props => props.theme?.primary_color ? `${props.theme.primary_color}99` : '#000000cc'};
    }
  }
`;

const Logo = styled.img`
  height: 40px;
  max-width: 200px;
  object-fit: contain;
`;

const Header = ({ logo, theme }) => {
  return (
    <HeaderWrapper theme={theme}>
      <Container>
        <Navbar expand="lg" className="navbar-light">
          <Link to="/" className="navbar-brand">
            <Logo src={logo || '/images/wcplogo.png'} alt="Portal Logo" />
          </Link>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/job-board">
                <i className="fas fa-briefcase me-1"></i>
                Jobs
              </Nav.Link>
              <Nav.Link as={Link} to="/companies">
                <i className="fas fa-building me-1"></i>
                Companies
              </Nav.Link>
              <Nav.Link as={Link} to="/career-tools">
                <i className="fas fa-toolbox me-1"></i>
                Career Tools
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
