import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme?.primary_color || '#343a40'};
  color: ${props => props.theme?.secondary_color || '#ffffff'};
  padding: 2rem 0;
  margin-top: auto;
`;

const FooterLink = styled.a`
  color: ${props => props.theme?.secondary_color || '#ffffff'};
  text-decoration: none;
  margin: 0 1rem;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
  }
`;

const PoweredByLogo = styled.img`
  height: 20px;
  margin-left: 0.5rem;
`;

const Footer = ({ portalData }) => {
  const theme = portalData?.settings?.theme;
  const logo = portalData?.settings?.logo_url || '/images/wcplogo.png';
  
  return (
    <FooterWrapper theme={theme}>
      <Container>
        <Row className="align-items-center">
          <Col md={4}>
            <div className="d-flex align-items-center">
              <span>Powered by</span>
              <PoweredByLogo src={logo} alt="Portal Logo" />
            </div>
          </Col>
          <Col md={8} className="text-md-end">
            <FooterLink href="/privacy" theme={theme}>Privacy Policy</FooterLink>
            <FooterLink href="/terms" theme={theme}>Terms of Service</FooterLink>
            <FooterLink href="/contact" theme={theme}>Contact Us</FooterLink>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
