import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import AdminBreadcrumb from './components/admin/AdminBreadcrumb';

// Lazy load public components
const Home = React.lazy(() => import('./components/Home'));
const JobBoard = React.lazy(() => import('./components/JobBoard'));
const JobListing = React.lazy(() => import('./components/JobListing'));
const Interview = React.lazy(() => import('./components/Interview'));
const InterviewFeedback = React.lazy(() => import('./components/InterviewFeedback'));
const InterviewReport = React.lazy(() => import('./components/InterviewReport'));
const JobApplication = React.lazy(() => import('./components/JobApplication'));
const CareerCoach = React.lazy(() => import('./components/CareerCoach'));
const CareerCourses = React.lazy(() => import('./components/CareerCourses'));
const CareerTools = React.lazy(() => import('./components/CareerTools'));
const InterviewPrep = React.lazy(() => import('./components/InterviewPrep'));
const NetworkingGuide = React.lazy(() => import('./components/NetworkingGuide'));
const CompanyDetail = React.lazy(() => import('./components/CompanyDetail'));
const CompanyHome = React.lazy(() => import('./components/CompanyHome'));
const CompanyBoard = React.lazy(() => import('./components/CompanyBoard'));
const IndustryDetail = React.lazy(() => import('./components/IndustryDetail'));

// Lazy load admin components
const AdminLogin = React.lazy(() => import('./components/admin/AdminLogin'));
const AdminDashboard = React.lazy(() => import('./components/admin/AdminDashboard'));
const JobEditor = React.lazy(() => import('./components/admin/JobEditor'));
const JobList = React.lazy(() => import('./components/admin/JobList'));
const JobDataUpload = React.lazy(() => import('./components/admin/JobDataUpload'));
const CompanyEditor = React.lazy(() => import('./components/admin/CompanyEditor'));
const CompanyList = React.lazy(() => import('./components/admin/CompanyList'));
const IndustriesPage = React.lazy(() => import('./components/admin/IndustriesPage'));
const IndustryEditor = React.lazy(() => import('./components/admin/IndustryEditor'));
const CareerList = React.lazy(() => import('./components/admin/CareerList'));
const CollectionList = React.lazy(() => import('./components/admin/CollectionList'));
const CollectionEdit = React.lazy(() => import('./components/admin/CollectionEdit'));
const CollectionManage = React.lazy(() => import('./components/admin/CollectionManage'));
const CourseList = React.lazy(() => import('./components/admin/CourseList'));
const CourseEditor = React.lazy(() => import('./components/admin/CourseEditor'));
const CoursePlayer = React.lazy(() => import('./components/admin/CoursePlayer'));
const LessonList = React.lazy(() => import('./components/admin/LessonList'));
const LessonEditor = React.lazy(() => import('./components/admin/LessonEditor'));
const CourseGenerator = React.lazy(() => import('./components/admin/CourseGenerator'));
const PartnerList = React.lazy(() => import('./components/admin/PartnerList'));
const PartnerEditor = React.lazy(() => import('./components/admin/PartnerEditor'));
const PortalList = React.lazy(() => import('./components/admin/PortalList'));
const PortalEditor = React.lazy(() => import('./components/admin/PortalEditor'));
const PageManager = React.lazy(() => import('./components/admin/PageManager'));
const PageContent = React.lazy(() => import('./components/pages/PageContent'));

// Lazy load portal components
const PortalRouter = React.lazy(() => import('./components/PortalRouter'));

// Loading component for suspense fallback
const Loading = () => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

// Create a separate component for the app content
const AppContent = () => {
  const location = useLocation();
  const [portalData, setPortalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPortalDomain, setIsPortalDomain] = useState(false);

  // Initialize Matomo Tag Manager
  useEffect(() => {
    window._mtm = window._mtm || [];
    window._mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://log.definedlearning.com/js/container_TPxL94eD.js';
    
    document.head.appendChild(script);
    
    // Cleanup function to remove the script if component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array means this runs once on mount

  console.log('\n=== AppContent Debug ===');
  console.log('Current location:', location.pathname);
  console.log('Current portal data:', portalData);
  console.log('Is portal domain:', isPortalDomain);

  // Fetch portal data on mount
  useEffect(() => {
    const fetchPortalData = async () => {
      try {
        console.log('Fetching portal data from API');
        const response = await fetch('/api/portal/current');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Portal data response:', data);
        
        if (data) {
          console.log('Setting portal data and isPortalDomain');
          setPortalData(data);
          setIsPortalDomain(true);
          window.__INITIAL_PORTAL_DATA__ = data;
        } else {
          console.log('No portal data returned');
          setPortalData(null);
          setIsPortalDomain(false);
          window.__INITIAL_PORTAL_DATA__ = null;
        }
      } catch (error) {
        console.error('Error fetching portal data:', error);
        setPortalData(null);
        setIsPortalDomain(false);
        window.__INITIAL_PORTAL_DATA__ = null;
      } finally {
        setLoading(false);
      }
    };

    // Only fetch on mount or when returning to root
    if (!window.__INITIAL_PORTAL_DATA__ || location.pathname === '/') {
      fetchPortalData();
    } else {
      // Use cached portal data
      setPortalData(window.__INITIAL_PORTAL_DATA__);
      setIsPortalDomain(true);
      setLoading(false);
    }
  }, []); // Only run on mount

  // Handle portal data persistence across navigation
  useEffect(() => {
    if (window.__INITIAL_PORTAL_DATA__) {
      setPortalData(window.__INITIAL_PORTAL_DATA__);
      setIsPortalDomain(true);
    }
  }, [location.pathname]);

  // Helper to determine if we're on a portal-specific page
  const isPortalPage = () => {
    return location.pathname === '/' || location.pathname.startsWith('/p/');
  };

  // Helper to get header props
  const getHeaderProps = () => {
    if (isPortalDomain && portalData?.settings) {
      return {
        logo: portalData.settings.logo_url,
        theme: portalData.settings.theme
      };
    }
    return {};
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Show header on sub-pages or when not on a portal domain */}
      {(!isPortalDomain || !isPortalPage()) && <Header {...getHeaderProps()} />}
      {(!isPortalDomain || !isPortalPage()) && <AdminBreadcrumb />}
      
      <main className="flex-grow-1">
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={
              isPortalDomain ? (
                <React.Fragment>
                  {console.log('Rendering PortalRouter with data:', portalData)}
                  <PortalRouter portalData={portalData} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {console.log('Rendering Home component - not a portal domain')}
                  <Home />
                </React.Fragment>
              )
            } />
            <Route path="/p/:slug/*" element={<PortalRouter portalData={portalData} />} />
            <Route path="/pages/:slug" element={<PageContent />} />
            <Route path="/jobs" element={<JobBoard />} />
            <Route path="/job-board" element={<JobBoard />} />
            <Route path="/jobs/:id" element={<JobListing />} />
            <Route path="/job-listing/:id" element={<JobListing />} />


            <Route path="/interview" element={<Interview />} />
            <Route path="/interview/:id" element={<Interview />} />

            <Route path="/feedback/:referenceId" element={<InterviewFeedback />} />
            <Route path="/interview-report/:referenceId" element={<InterviewReport />} />
            <Route path="/career-courses" element={<CareerCourses />} />
            <Route path="/apply/:id" element={<JobApplication />} />
            <Route path="/career-coach" element={<CareerCoach />} />
            <Route path="/career-tools" element={<CareerTools />} />
            <Route path="/tools/interview-prep" element={<InterviewPrep />} />
            <Route path="/tools/networking" element={<NetworkingGuide />} />
            <Route path="/companies" element={<CompanyBoard />} />
            <Route path="/companies/:id" element={<CompanyDetail />} />
            <Route path="/companyhome/:id" element={<CompanyHome />} />
            <Route path="/company-board" element={<CompanyBoard />} />
            <Route path="/industries/:id" element={<IndustryDetail />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            
            <Route path="/admin/jobs" element={<JobList />} />
            <Route path="/admin/jobs/new" element={<JobEditor />} />
            <Route path="/admin/jobs/:id/edit" element={<JobEditor />} />
            <Route path="/admin/jobs/edit/:id" element={<JobEditor />} />
            <Route path="/admin/jobs/upload" element={<JobDataUpload />} />
            
            <Route path="/admin/companies" element={<CompanyList />} />
            <Route path="/admin/companies/new" element={<CompanyEditor />} />
            <Route path="/admin/companies/:id/edit" element={<CompanyEditor />} />
            
            <Route path="/admin/industries" element={<IndustriesPage />} />
            <Route path="/admin/industries/new" element={<IndustryEditor />} />
            <Route path="/admin/industries/:id/edit" element={<IndustryEditor />} />
            <Route path="/admin/industries/:id" element={<IndustryDetail />} />
            
            <Route path="/admin/careers" element={<CareerList />} />
            <Route path="/admin/collections" element={<CollectionList />} />
            <Route path="/admin/collections/:id" element={<CollectionEdit />} />
            <Route path="/admin/collections/:id/manage" element={<CollectionManage />} />
            
            <Route path="/admin/courses" element={<CourseList />} />
            <Route path="/admin/courses/new" element={<CourseEditor />} />
            <Route path="/admin/courses/:id/edit" element={<CourseEditor />} />
            <Route path="/admin/courses/:id/player" element={<CoursePlayer />} />
            <Route path="/admin/lessons" element={<LessonList />} />
            <Route path="/admin/lessons/new" element={<LessonEditor />} />
            <Route path="/admin/lessons/:id" element={<LessonEditor />} />
            <Route path="/admin/course-generator" element={<CourseGenerator />} />

            <Route path="/admin/partners" element={<PartnerList />} />
            <Route path="/admin/partners/new" element={<PartnerEditor />} />
            <Route path="/admin/partners/:id/edit" element={<PartnerEditor />} />

            <Route path="/admin/portals" element={<PortalList />} />
            <Route path="/admin/portals/new" element={<PortalEditor />} />
            <Route path="/admin/portals/edit/:id" element={<PortalEditor />} />
            <Route path="/admin/pages" element={<PageManager />} />
          </Routes>
        </Suspense>
      </main>
      
      {/* Show footer on sub-pages or when not on a portal domain */}
      {(!isPortalDomain || !isPortalPage()) && <Footer />}
    </div>
  );
};

// Main App component
function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AppContent />
      </Router>
    </ErrorBoundary>
  );
}

export default App;
